<template>
    <button 
      class="catalogues__button"
      @click="$emit('showModal')"
      >
      Crear Sucursal
    </button>
  </template>
  
  <script>
  export default {
      name: 'CreateCatalogueButton'
  }
  </script>
  
  <style>
  
  </style>
  